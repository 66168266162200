import React, { FC, useEffect, useRef, useState } from 'react';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform,
  useIonRouter,
} from '@ionic/react';
import { Box, Grid, Skeleton, Typography, useMediaQuery, Link } from '@mui/material';
import { PigogoButton, theme } from 'components';
import AlertSection from '../components/Home/AlertSection';
import PigogoFooter from '../components/PigogoFooter';
import RelatedStores from '../components/SelectedStore/RelatedStores';
import StoreDesktop from '../components/SelectedStore/StoreDesktop';
import StoreMobile from '../components/SelectedStore/StoreMobile';
import { useShopQuery } from '../redux/api/queries/shopQuery';
import LoginModal from '../components/Navigation/Modals/LoginModal';
import { useLazySelectedCategoryQuery } from '../redux/api/queries/categoriesQuery';
import Layout from '../components/Navigation/Layout';
import CustomContainer from '../components/CustomContainer';
import { viewedShop } from '../utils/viewedRecently';
import ForgotModal from '../components/Navigation/Modals/ForgotModal';
import SuccessPasswordModal from '../components/Navigation/Modals/SuccessPasswordModal';
import { useGetUserDataQuery } from '../redux/api/queries/userQueries';
import RedirectModal from '../components/Navigation/Modals/RedirectModal';
import RegisterModal from '../components/Navigation/Modals/RegisterModal';
import { Browser } from '@capacitor/browser';
import { useAppSelector } from '../redux/hooks';
import backArrow from '../assets/svgs/back_arrow.svg';
import { useHistory } from 'react-router';
import { isDesktopWeb, isWeb } from '../utils/device';
import { ReactComponent as PigogoLogo } from '../assets/PigogoLogo.svg';
import { Helmet } from 'react-helmet-async';
import AdBlockerModal from '../components/Navigation/Modals/AdBlockerModal';
import moment from 'moment';
import { useUpdateUserMutation } from '../redux/api/mutations/userMutations';
import AdBlockIcon from '../assets/png/adblockers.png';

type Props = {
  shopSlugName: string;
};
const SelectedStore: FC<Props> = ({ shopSlugName }) => {
  const router = useIonRouter();
  const history = useHistory();

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const desktop = isPlatform('desktop');
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const { data: userData } = useGetUserDataQuery();
  const [updateUser, { isError: updateError, reset: resetUser }] = useUpdateUserMutation();

  const [message, setMessage] = useState<string>('');
  const [footerMessage, setFooterMessage] = useState<string>('');
  const [footerRedirLink, setFooterRedirLink] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [forgotModal, setForgotModal] = useState<boolean>(false);
  const [redirectModal, setRedirectModal] = useState<boolean>(false);
  const [registerModal, setRegisterModal] = useState<boolean>(false);
  const [successForgotModal, setSuccessForgotModal] = useState<boolean>(false);

  const [shopLoading, setShopLoading] = useState<boolean>(true);
  const [categoryLoading, setCategoryLoading] = useState<boolean>(true);
  const [adblockerImgLoaded, setAdblockerImgLoaded] = useState<boolean>(false);

  const {
    data: shop,
    isLoading: loadShop,
    isFetching: fetchShop,
    isSuccess: shopFetched,
    isError: shopError,
  } = useShopQuery(
    {
      slugName: (shopSlugName || router.routeInfo.pathname).replaceAll('/', ''),
    },
    {
      refetchOnMountOrArgChange: false,
    },
  );
  const [getCategory, { data: category, isFetching: loadName, isSuccess }] = useLazySelectedCategoryQuery();

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const [adblockers, setAdblockers] = useState<boolean>(false);
  const web = isDesktopWeb(); //const desktop = false;

  const isCapacitorApp = !isWeb();

  const {
    name = ' ',
    image = ' ',
    url = ' ',
    cashback_categories = [],
    cashback = ' ',
    currency = ' ',
    menu_categories = [],
  } = shop ? shop : {};

  useEffect(() => {
    if (menu_categories.length > 0) {
      setCategoryLoading(true);
      getCategory({ id: menu_categories[0].toString() }, true);
      setTimeout(() => {
        setCategoryLoading(false);
      }, 500);
    }
    if (shop && userData && userData.id) {
      viewedShop(shop, userData.id);
    }
  }, [getCategory, menu_categories, shop, userData]);

  useEffect(() => {
    setTimeout(() => {
      setCategoryLoading(false);
    }, 400);
  }, [loadName]);

  useEffect(() => {
    history.action !== 'PUSH' &&
      contentRef.current &&
      contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
    history.action === 'PUSH' && contentRef.current && contentRef.current.scrollToPoint(0, 0, 10);

    !!shop && setShopLoading(false);
  }, [shop]);

  useEffect(() => {
    shopError && setShopLoading(false);
  }, [shopError]);

  useEffect(() => {
    if (!shopLoading) return;
    history.action !== 'PUSH' &&
      contentRef.current &&
      contentRef.current.scrollToPoint(0, (history.location.state as any)?.scrollY ?? 0, 10);
    history.action === 'PUSH' && contentRef.current && contentRef.current.scrollToPoint(0, 0, 10);
  }, [shopLoading]);

  useEffect(() => {
    if (loadShop || fetchShop) {
      history.action === 'PUSH' && contentRef.current && contentRef.current.scrollToPoint(0, 0, 10);
      setShopLoading(true);
      setCategoryLoading(true);
    }
  }, [loadShop, fetchShop]);

  useEffect(() => {
    if (web && userData && shop) {
      if (
        Math.abs(moment(userData.shown_adblockers_modal_date).diff(moment(new Date()), 'months', true)) >= 1 ||
        !userData.shown_adblockers_modal_date
      ) {
        new Promise((res) => {
          const shopImage = new Image();
          shopImage.src = AdBlockIcon as string;
          shopImage.onload = () => setAdblockerImgLoaded(true);
          shopImage.append();
          res(shopImage);
        }).then((res) => {
          console.log(res);
          const adDomain: any = fetch('//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js')
            .then((result) => {
              if (result.url.includes('extension') || result.url.includes('addon')) {
                setAdblockers(true);

                updateUser({
                  shown_adblockers_modal_date: moment().tz('Europe/Athens').toISOString(),
                });
              } else {
                const _adAttribute = 'adheader';

                const div = document.createElement('div');
                div.setAttribute('id', _adAttribute);
                div.classList.add('ad', 'ads', 'adv', 'advert', 'advertisement', 'banner', 'banners');
                div.setAttribute(
                  'style',
                  'height:1px !important; min-height:1px !important; max-height:1px !important; background-color: transparent;',
                );

                document.getElementsByTagName('body')[0]?.appendChild(div);
                const _div = document.getElementById(_adAttribute);

                if (!_div || _div.offsetHeight !== 1) {
                  setAdblockers(true);

                  updateUser({
                    shown_adblockers_modal_date: moment().tz('Europe/Athens').toISOString(),
                  });
                }
              }
            })
            .catch((result) => {
              setAdblockers(true);

              updateUser({
                shown_adblockers_modal_date: moment().tz('Europe/Athens').toISOString(),
              });
            });
        });
      }
    }
  }, [shop, userData]);

  function handleScrollEnd(e: any) {
    history.location.pathname === `/${shopSlugName}` &&
      e.target.detail.scrollTop &&
      history.replace({ state: { scrollY: e.target.detail.scrollTop } });
  }

  const handleClickStore = async () => {
    if (!!accessToken || sessionLoggedIn) {
      setRedirectModal(true);

      if (isCapacitorApp) {
        await Browser.open({ url: url });
      } else {
        window.open(url, '_blank');
      }
    } else {
      setLoginModal(true);
      setMessage('Για να κερδίσεις επιστροφή χρημάτων πρέπει πρώτα να συνδεθείς στο Pigogo.');
      setFooterMessage('Μεταφορά χωρίς επιστροφή χρημάτων');
      setFooterRedirLink && setFooterRedirLink(shop?.urlWithoutCashback || '');
    }
  };

  const stickyBoxStore = () => {
    return (
      <Box
        style={{
          position: 'sticky',
          bottom: 0,
          zIndex: 3000,
          padding: '16px 24px',
          background: 'white',
          boxShadow: ' 0px -4px 8px rgba(0, 0, 0, 0.08)',
        }}
      >
        <Grid container item xs={12} alignItems={'center'} justifyContent={'center'} spacing={2}>
          <Grid container item xs={12} sm={7} justifyContent={'center'}>
            {loadShop ? (
              <Skeleton
                animation="wave"
                style={{ background: '#EAECEE', borderRadius: '16px' }}
                width={'100%'}
                height={'40px'}
              />
            ) : (
              <PigogoButton
                fullWidth
                variation={'secondary'}
                text={'Μεταφορά στο κατάστημα'}
                onClick={() => handleClickStore()}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const checkElementView = () => {
    const storeCardView = document.getElementById('#storeCard');
    const position = storeCardView?.getBoundingClientRect();

    // detecting if element is fully visible
    if (position && position.top >= 0 && position.bottom <= window.innerHeight) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  const getContentSelectedStore = () => {
    if (matches) {
      return (
        <StoreMobile
          shop={shop}
          shopLoad={shopLoading}
          setLogin={setLoginModal}
          message={setMessage}
          setRedirectModal={setRedirectModal}
          footerMessage={setFooterMessage}
          shopSlug={shopSlugName}
          footerRedirLink={setFooterRedirLink}
          contentRef={contentRef}
        />
      );
    } else {
      return (
        <StoreDesktop
          shop={shop}
          shopLoad={shopLoading}
          setLogin={setLoginModal}
          message={setMessage}
          setRedirectModal={setRedirectModal}
          footerMessage={setFooterMessage}
          shopSlug={shopSlugName}
          footerRedirLink={setFooterRedirLink}
          contentRef={contentRef}
        />
      );
    }
  };

  const renderTitle = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gap={2} pt={4}>
        {categoryLoading || shopLoading ? (
          <Skeleton
            animation="wave"
            style={{ background: '#EAECEE', borderRadius: '16px' }}
            width={'10%'}
            height={'16px'}
          />
        ) : (
          <Link
            variant="captionM"
            href={category?.slug.name}
            sx={{
              textDecoration: 'underline',
              textDecorationColor: '#FCE5D8',
              color: '#313D53',
              textUnderlineOffset: '4px',
              display: 'inline-block',
              padding: '0 2px',
              backgroundColor: 'transparent',
              transition: 'background-color .2s ease',
              '&:hover': {
                backgroundColor: '#FCE5D8',
                textDecorationColor: '#FCE5D8',
              },
            }}
          >
            {category ? category.label : ''}
          </Link>
        )}
        <Typography variant="h4" component="h2" color={'#313D53'}>
          {shopLoading ? (
            <Skeleton width={'130px'} animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} />
          ) : (
            name
          )}
        </Typography>
      </Box>
    );
  };

  const renderHeader = () => {
    return (
      <IonHeader class="ion-no-border">
        <IonToolbar
          style={{
            '--background': '#FFFFFF',
            '--border-width': 0,
            paddingTop: '0 + --ion-safe-area-top',
          }}
        >
          <>
            {!!accessToken || sessionLoggedIn ? null : (
              <Link
                href={'/'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                p={2}
                sx={{ borderBottom: '1px solid #DADCE0' }}
              >
                <PigogoLogo />
              </Link>
            )}
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2} p={2}>
              <IonButtons slot="start">
                <IonBackButton
                  className="backBtn"
                  text=""
                  style={{
                    '--color': '#313D53',
                    '--padding-start': 0,
                    '--padding-end': 0,
                    '--icon-font-size': '24px',
                    '--border-radius': 0,
                    '--min-height': '24px',
                    '--min-width': '24px',
                    width: '24px',
                    height: '24px',
                    minWidth: '24px',
                    maxHeight: '24px',
                    display: 'inline-flex',
                  }}
                  icon={backArrow}
                />
              </IonButtons>
              <Typography component="h2" variant="body2" color={'#313D53'}>
                {shopLoading ? (
                  <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'10%'} />
                ) : (
                  name
                )}
              </Typography>
              <Box minWidth={'24px'}></Box>
            </Box>
          </>
        </IonToolbar>
      </IonHeader>
    );
  };

  return (
    <IonPage>
      <IonContent
        ref={contentRef}
        scrollEvents={!loadShop}
        onIonScroll={() => checkElementView()}
        onIonScrollEnd={(e) => handleScrollEnd(e)}
      >
        <Layout>
          <Box>
            {tablet && renderHeader()}
            {location.pathname === '/' + shopSlugName && shopFetched && (
              <Helmet>
                <title>Επιστροφή χρημάτων {shop?.name}, προσφορές, κουπόνια</title>
                <meta
                  name="description"
                  content="Βρες προσφορές, εκπτώσεις και κουπόνια σε περισσότερα από 450 ηλεκτρονικά καταστήματα. Κέρδισε επιστροφή χρημάτων κάθε φορά που ψωνίζεις online."
                />
              </Helmet>
            )}
            <CustomContainer>
              <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={3}>
                {!mobile && !tablet && renderTitle()}
                {getContentSelectedStore()}
                <RelatedStores shop={shop} shopLoading={loadShop} />
              </Box>
            </CustomContainer>
            {!matches && !userData?.has_used_extension && <AlertSection />}
            {!matches && <PigogoFooter />}
          </Box>
        </Layout>
        <LoginModal
          message={message}
          setLoginModal={(value: boolean) => {
            setLoginModal(value);
          }}
          isOpen={loginModal}
          dismiss={() => setLoginModal(false)}
          footerMessage={footerMessage}
          footerRedir={() => {
            Browser.open({ url: shop?.urlWithoutCashback || '' });
          }}
          openForgotModal={() => setForgotModal(true)}
          openRegisterModal={() => setRegisterModal(true)}
          footerRedirLink={footerRedirLink || ''}
        />
        <RegisterModal isOpen={registerModal} setOpen={setRegisterModal} setLoginModal={setLoginModal} />
        <ForgotModal isOpen={forgotModal} setOpen={setForgotModal} success={setSuccessForgotModal} />
        <SuccessPasswordModal
          title={'Ευχαριστούμε πολύ!'}
          subTitle={'Το link δημιουργίας νέου κωδικού έχει σταλεί στο email σου.'}
          open={successForgotModal}
          handleClose={setSuccessForgotModal}
        />

        <RedirectModal
          extensionPigogo={userData ? userData.has_used_extension : false}
          open={redirectModal}
          handleClose={() => setRedirectModal(false)}
          icon={image}
          shop={name}
          subTitle="Προχώρησε κατευθείαν στην αγορά σου για να κερδίσεις επιστροφή χρημάτων!"
        />

        <AdBlockerModal
          open={web && adblockers && !shopLoading && adblockerImgLoaded}
          handleClose={() => {
            setAdblockers(false);
          }}
        />
      </IonContent>
      {matches && visible && stickyBoxStore()}
    </IonPage>
  );
};

export default SelectedStore;
